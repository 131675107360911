import { defineColumn, transferRowDataInternal } from "presentation/view/components/TableWrapper/BasicTableConstants";
import { CompanyConstant } from "./CompanyConstant";

const COMPANY_CONSTANT = CompanyConstant.Header;
let dateFieldList:string[] = [];
let dateTimeFieldList:string[] = [];
export const INITIAL_COMPANY_COL_DEF: any[] = [
    {
        headerName: COMPANY_CONSTANT.COMPANY_CODE,
        field: 'companyCode',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: false,
        width: 200,
        pinned: "left",
        checkboxSelection: true,
        headerCheckboxSelection: true,
    },
    {
        headerName: COMPANY_CONSTANT.COMPANY_NAME,
        field: 'companyName',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 180,
    },
    {
        headerName: COMPANY_CONSTANT.COMPANY_TYPE,
        field: 'companyType',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 180,
    },
    {
        headerName: COMPANY_CONSTANT.MASTER_COMPANY,
        field: 'masterCompany.companyCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 180,
    },
    {
        headerName: COMPANY_CONSTANT.ALLIANCE,
        field: 'alliance.companyCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 120,        
    },
    {
        headerName: COMPANY_CONSTANT.ADDRESS_1,
        field: 'address1',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 120,
    },
    {
        headerName: COMPANY_CONSTANT.ADDRESS_2,
        field: 'address2',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 120,
    },
    {
        headerName: COMPANY_CONSTANT.ADDRESS_3,
        field: 'address3',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 120,
    },
    {
        headerName: COMPANY_CONSTANT.STATUS,
        field: 'status',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 120,
    },
    {
        headerName: COMPANY_CONSTANT.SIGN_ON_DATE,
        field: 'signOnDate',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 150,
        dataType: "date"
    },
    {
        headerName: COMPANY_CONSTANT.COUNTRY_CODE,
        field: 'country.countryCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 150,
    },
    {
        headerName: COMPANY_CONSTANT.ACTIVE_IND,
        field: 'activeInd',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 130,        
    }

].map((col, index) => {
    const cellRenderers:{[key:string]:((params:{[key:string]:string}, fieldName:string) => {})} = {};

    return defineColumn(col, index, dateFieldList, dateTimeFieldList, [], cellRenderers);
});

export const transferRowData = (data:any[]) => {
    const externalFnctions:{[key:string]:((fieldName:string, row:any) => {})} = {};
    
    return transferRowDataInternal(data, dateFieldList, dateTimeFieldList, [], externalFnctions);
}